import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import heroImage from './assts/517140795.jpg';
import launchImage1 from './assts/517140796.jpg';
import launchImage2 from './assts/517140799.jpg';
import launchImage3 from './assts/517140802.jpg';
import compoundImage1 from './assts/517140807.jpg';
import compoundImage2 from './assts/517140812.jpg';
import compoundImage3 from './assts/517140816.jpg';
import areaImage1 from './assts/517140819.jpg';
import areaImage2 from './assts/517140821.jpg';
import areaImage3 from './assts/533505590.jpg';

// Main App Component
function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="App">
      <Header />
      <HeroSection />
      <QuickLinks />
      <NewLaunches />
      <TopCompounds />
      <TopAreas />
      <AboutUs />
      <Contact />
      <Footer />
    </div>
  );
}

// Header Component
function Header() {
  return (
    <header>
      <nav className="navbar" data-aos="fade-down">
        <div className="navbar-logo">
          <h1>Real Estate Consulting Co.</h1>
        </div>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#new-launches">New Launches</a></li>
          <li><a href="#top-compounds">Top Compounds</a></li>
          <li><a href="#top-areas">Top Areas</a></li>
        </ul>
      </nav>
    </header>
  );
}

// Hero Section Component
function HeroSection() {
  return (
    <section id="home" className="hero-section" data-aos="zoom-in" style={{ backgroundImage: `url(${heroImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="hero-content">
        <h2>Find Your New Home</h2>
        <p>Search & compare among 5000+ properties and 500+ compounds or list your property for sale.</p>
        <button data-aos="fade-up" className="learn-more-btn">Learn More</button>
      </div>
    </section>
  );
}

// Quick Links Section Component
function QuickLinks() {
  return (
    <section className="quick-links-section">
      <div className="quick-link" data-aos="fade-right">
        <h3>Nawy Now</h3>
        <p>Don't Wait, Move Now & Pay Later With Nawy Now</p>
        <a href="#">Learn More</a>
      </div>
      <div className="quick-link" data-aos="fade-left">
        <h3>Sell Your Property</h3>
        <p>Sell your property in 3 simple steps</p>
        <a href="#">Learn More</a>
      </div>
    </section>
  );
}

// New Launches Component
function NewLaunches() {
  return (
    <section id="new-launches" className="new-launches-section" data-aos="fade-up">
      <h2>New Launches</h2>
      <div className="launches-grid">
        <div className="launch-item" data-aos="fade-up">
          <img src={launchImage1} alt="Launch 1" />
          <h3>New Residence</h3>
        </div>
        <div className="launch-item" data-aos="fade-up">
          <img src={launchImage2} alt="Launch 2" />
          <h3>Neighborhood by Iwan</h3>
        </div>
        <div className="launch-item" data-aos="fade-up">
          <img src={launchImage3} alt="Launch 3" />
          <h3>Ajna</h3>
        </div>
      </div>
    </section>
  );
}

// Top Compounds Component
function TopCompounds() {
  return (
    <section id="top-compounds" className="top-compounds-section" data-aos="fade-up">
      <h2>Top Compounds</h2>
      <div className="compounds-grid">
        <div className="compound-item" data-aos="fade-right">
          <img src={compoundImage1} alt="Compound 1" />
          <h3>ZED East</h3>
          <p>50 Properties Available</p>
        </div>
        <div className="compound-item" data-aos="fade-up">
          <img src={compoundImage2} alt="Compound 2" />
          <h3>Il Bosco New Capital</h3>
          <p>91 Properties Available</p>
        </div>
        <div className="compound-item" data-aos="fade-left">
          <img src={compoundImage3} alt="Compound 3" />
          <h3>Monte Galala</h3>
          <p>132 Properties Available</p>
        </div>
      </div>
    </section>
  );
}

// Top Areas Component
function TopAreas() {
  return (
    <section id="top-areas" className="top-areas-section" data-aos="fade-up">
      <h2>Top Areas</h2>
      <div className="areas-grid">
        <div className="area-item" data-aos="fade-right">
          <img src={areaImage1} alt="New Cairo" />
          <h3>New Cairo</h3>
          <p>287 Compounds Available</p>
        </div>
        <div className="area-item" data-aos="fade-up">
          <img src={areaImage2} alt="6th of October City" />
          <h3>6th of October City</h3>
          <p>193 Compounds Available</p>
        </div>
        <div className="area-item" data-aos="fade-left">
          <img src={areaImage3} alt="New Capital City" />
          <h3>New Capital City</h3>
          <p>1403 Properties Available</p>
        </div>
      </div>
    </section>
  );
}

// About Us Component
function AboutUs() {
  return (
    <section id="about" className="about-section" data-aos="fade-up">
      <h2>About Us</h2>
      <p>We are a team of experienced consultants specializing in real estate investments and market analysis. With years of experience, we strive to provide top-notch service to our clients, ensuring they make informed decisions with confidence.</p>
    </section>
  );
}

// Contact Component
function Contact() {
  return (
    <section id="contact" className="contact-section" data-aos="fade-up">
      <h2>Contact Us</h2>
      <p>If you have any questions, feel free to reach out to us!</p>
      <form data-aos="zoom-in">
        <input type="text" placeholder="Your Name" className="input-field" />
        <input type="email" placeholder="Your Email" className="input-field" />
        <textarea placeholder="Your Message" className="input-field"></textarea>
        <button type="submit" className="send-btn">Send Message</button>
      </form>
    </section>
  );
}

// Footer Component
function Footer() {
  return (
    <footer data-aos="fade-up">
      <div className="social-media">
        <a href="#" aria-label="Facebook"><FaFacebook /></a>
        <a href="#" aria-label="Twitter"><FaTwitter /></a>
        <a href="#" aria-label="Instagram"><FaInstagram /></a>
      </div>
      <p>Real Estate Consulting Co. &copy; 2024. All rights reserved.</p>
    </footer>
  );
}

// Rendering the React App
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

export default App;
